<template>
    <div class="warp"
         v-loading="Loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="main">
        <div style="position: absolute;left: 118px;top:62px;">
          <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content">
                  <div style="height:100px;width: 390px;color:#e54848;">
                    <el-row :gutter="12">
                      <el-col :span="12">
                        <div style="text-align: center;margin-bottom: 6px;"><span>当前基础属性</span></div>
                        <ul>
                          <li>
                            H P：{{info.basis.dq.maxHP}}
                          </li>
                          <li>
                            M P：{{info.basis.dq.maxMP}}
                          </li>
                          <li>
                            力量：{{info.basis.dq.phy_attack}}
                          </li>
                          <li>
                            智力：{{info.basis.dq.mag_attack}}
                          </li>
                          <li>
                            体力：{{info.basis.dq.phy_defense}}
                          </li>
                          <li>
                            精力：{{info.basis.dq.mag_defense}}
                          </li>
                          <li>
                            移动速度：{{info.basis.dq.move_speed}}
                          </li>
                          <li>
                            攻击速度：{{info.basis.dq.attack_speed}}
                          </li>
                          <li>
                            施放速度：{{info.basis.dq.cast_speed}}
                          </li>
                          <li>
                            火属性抗性：{{info.basis.dq.element_resist_h}}
                          </li>
                          <li>
                            冰属性抗性：{{info.basis.dq.element_resist_b}}
                          </li>
                          <li>
                            光属性抗性：{{info.basis.dq.element_resist_g}}
                          </li>
                          <li>
                            暗属性抗性：{{info.basis.dq.element_resist_a}}
                          </li>
                          <li>
                            稀有幸运值：{{info.basis.dq.lucky}}
                          </li>
                        </ul>
                      </el-col>
                      <el-col :span="12">
                        <div style="text-align: center;margin-bottom: 6px;"><span>历史获得属性</span></div>
                        <ul>
                          <li>
                            H P：{{info.basis.ls.maxHP}}
                          </li>
                          <li>
                            M P：{{info.basis.ls.maxMP}}
                          </li>
                          <li>
                            力量：{{info.basis.ls.phy_attack}}
                          </li>
                          <li>
                            智力：{{info.basis.ls.mag_attack}}
                          </li>
                          <li>
                            体力：{{info.basis.ls.phy_defense}}
                          </li>
                          <li>
                            精力：{{info.basis.ls.mag_defense}}
                          </li>
                          <li>
                            移动速度：{{info.basis.ls.move_speed}}
                          </li>
                          <li>
                            攻击速度：{{info.basis.ls.attack_speed}}
                          </li>
                          <li>
                            施放速度：{{info.basis.ls.cast_speed}}
                          </li>
                          <li>
                            火属性抗性：{{info.basis.ls.element_resist_h}}
                          </li>
                          <li>
                            冰属性抗性：{{info.basis.ls.element_resist_b}}
                          </li>
                          <li>
                            光属性抗性：{{info.basis.ls.element_resist_g}}
                          </li>
                          <li>
                            暗属性抗性：{{info.basis.ls.element_resist_a}}
                          </li>
                          <li>
                            稀有幸运值：{{info.basis.ls.lucky}}
                          </li>
                          <li>
                            所有技能级：{{info.basis.ls.skills}}（封顶20级）
                          </li>
                        </ul>
                      </el-col>
                      <el-col :span="24">
                        <p style="color:#9d6565;margin-top:10px;">温馨提示：当前面板显示的是角色基本属性，不参与（装备/武器/时装）等加成，与游戏面板显示存在一定的差异。</p>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <span style="background: #383636;padding: 2px 6px;border: 1px solid rgb(75 75 75);">属性对比</span>
            </el-tooltip>
         </div>
        <div style="position: absolute;">
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content">
                  <div v-if="info.soaring.length > 0" style="overflow:auto;height:100px;width: 290px;color:#e54848;">
                      <p v-for="(vcc, i) in info.soaring" :key="i">飞升获得：{{vcc.title}}</p>
                  </div>
                  <span style="color:#6d9a00;" v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e11.state == 0" class="ioc-huaqi-0-12"></i>
                <i v-if="info.evolution.e11.state == 1" class="ioc-huaqi-1-12"></i>
            </el-tooltip>
            
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e10.skills != ''">{{info.evolution.e10.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e10.state == 0" class="ioc-huaqi-0-11"></i>
                <i v-if="info.evolution.e10.state == 1" class="ioc-huaqi-1-11"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e6.skills != ''">{{info.evolution.e6.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e6.state == 0" class="ioc-huaqi-0-10"></i>
                <i v-if="info.evolution.e6.state == 1" class="ioc-huaqi-1-10"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e5.skills != ''">{{info.evolution.e5.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e5.state == 0" class="ioc-huaqi-0-9"></i>
                <i v-if="info.evolution.e5.state == 1" class="ioc-huaqi-1-9"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e3.skills != ''">{{info.evolution.e3.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e3.state == 0" class="ioc-huaqi-0-8"></i>
                <i v-if="info.evolution.e3.state == 1" class="ioc-huaqi-1-8"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e2.skills != ''">{{info.evolution.e2.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e2.state == 0" class="ioc-huaqi-0-7"></i>
                <i v-if="info.evolution.e2.state == 1" class="ioc-huaqi-1-7"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e9.skills != ''">{{info.evolution.e9.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e9.state == 0" class="ioc-huaqi-0-6"></i>
                <i v-if="info.evolution.e9.state == 1" class="ioc-huaqi-1-6"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e8.skills != ''">{{info.evolution.e8.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e8.state == 0" class="ioc-huaqi-0-5"></i>
                <i v-if="info.evolution.e8.state == 1" class="ioc-huaqi-1-5"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e7.skills != ''">{{info.evolution.e7.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e7.state == 0" class="ioc-huaqi-0-4"></i>
                <i v-if="info.evolution.e7.state == 1" class="ioc-huaqi-1-4"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
              <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e4.skills != ''">{{info.evolution.e4.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e4.state == 0" class="ioc-huaqi-0-3"></i>
                <i v-if="info.evolution.e4.state == 1" class="ioc-huaqi-1-3"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
                <div slot="content" style="color:#6d9a00;">
                  <span v-if="info.evolution.e1.skills != ''">{{info.evolution.e1.skills}}</span>
                  <span v-else>本进化无属性加成</span>
                </div>
                <i v-if="info.evolution.e1.state == 0" class="ioc-huaqi-0-2"></i>
                <i v-if="info.evolution.e1.state == 1" class="ioc-huaqi-1-2"></i>
            </el-tooltip>
        </div>
        <div style="position: absolute;right: 336px;top:474px;width:303px;">
            <p style="text-align:center;">当前角色：{{info.charac_name}} <span style="color:#914343; cursor:pointer" @click="LoginDisplay = 'display:block'">《换绑》</span></p>  
            
        </div>
        <div style="position: absolute;right: 17px;top:48px;width:310px;">
            <ul class="tesk-lb">
                <li v-for="(vcc, i) in info.task_lis" :key="i">
                    <el-row :gutter="12">
                        <el-col :span="11">
                            <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="left-start">
                                <div slot="content" style="width:270px;">
                                    <p style="color: #c5b48e;margin-bottom: 4px;"><span style="color: #fff;">标题：</span>{{vcc.task_name}}</p>
                                    <p style="color: #c5b48e;margin-bottom: 4px;"><span style="color: #fff;">完成：</span>({{vcc.wc_num}}/{{vcc.num}})</p>
                                    <p style="color: #c5b48e;margin-bottom: 4px;"><span style="color: #fff;">能量：</span>{{vcc.exp}}（个）</p>
                                </div>
                                <span class="task-title"><span style="color:#c80000;">[每日]</span>{{vcc.task_name}}</span>
                            </el-tooltip>
                        </el-col>
                        <el-col :span="6">
                            <div style="text-align: center;"><span>({{vcc.wc_num}}/{{vcc.num}})</span></div>
                        </el-col>
                        <el-col :span="3">
                            <div style="text-align: center;"><span>{{vcc.exp}}</span></div>
                        </el-col>
                        <el-col :span="4">
                            <div style="text-align: center;" v-if="vcc.state == 1"><span class="bin-an" @click="MshTask(vcc.id)">领取</span></div>
                            <div style="text-align: center;" v-if="vcc.state == 0"> <span style="color:#574d4d">待完成</span></div>
                            <div style="text-align: center;" v-if="vcc.state == 2"> <span style="color:rgb(183 136 136);text-decoration: line-through;">已完成</span></div>
                        </el-col>
                    </el-row>
                </li> 
            </ul>
        </div>
        <div style="position: absolute;right: 15px;top:349px;width:293px;padding:0px 10px;">
            <div style="color:#7c1c1c;margin-top:22px;">
                <p>温馨提示： <el-tooltip class="item" effect="dark" content="" placement="left">
                    <div slot="content" style="width: 290px;color:#c5b48e;">
                        <p>1.集满能量，可以激活一层进化，每层进化都可以获得不一样的属性加成，当11层进化全部激活后，可达飞升，并重置所有进化。进化属性会累计加成，飞升次数越高，属性加成越高。</p>
                        <p style="margin-top: 6px;">2.进化有一定的成功概率，初始化为：{{info.plug_msh.probability}}%，<span v-if="info.plug_msh.probability_buy_type > 0">可通过[<span v-if="info.plug_msh.probability_buy_type == 1">点卷</span><span v-if="info.plug_msh.probability_buy_type == 2">代币</span>]购买进行提高成功概率，进化后，概率重置回{{info.plug_msh.probability}}%。</span></p>
                    </div>
                    <i class="el-icon-question"></i>
                </el-tooltip></p>  
            </div>
            <div>
                <div style="margin-top:4px;">
                    <span>当前概率</span>
                    <span style="margin-left:10px;">{{info.plug_msh.dq_probability}}%</span>
                    <span v-if="info.plug_msh.probability_buy_type > 0" style="background: #982907;padding: 2px 6px;border: 1px solid #856b3e;border-radius: 2px;margin-left: 10px;cursor:pointer;" @click="BuyDisplay = true">提升</span>
                </div>
                <div style="margin-top:4px;">
                    <span>当前飞升</span>
                    <span style="margin-left:10px;">Lv{{info.plug_msh.level}}</span>
                    <el-tooltip class="item" effect="dark" content="" placement="left">
                        <div slot="content" style="width: 290px;color:#c5b48e;">
                            <p style="text-align:center;margin-bottom:10px;">当前飞升：Lv{{info.plug_msh.level}}</p>
                            <div style="overflow:auto;height:100px;color:#e54848;">
                                <p v-for="(vcc, i) in info.soaring" :key="i">下次飞升获得：{{vcc.title}}</p>
                            </div>
                        </div>
                        <i class="el-icon-question" style="margin-left:6px;color:#f90000"></i>
                    </el-tooltip>
                </div>
                <div style="text-align:center;">
                    <span>({{info.plug_msh.dq_energy_num}}/{{info.plug_msh.energy_num}})</span>
                </div>
                <div>
                    <span>能量进度</span>
                    <el-progress :percentage="info.plug_msh.percentage" style="display:inline-block;width:234px;margin-left:10px;"></el-progress>
                </div>
                <div>
                    <span>可进化次</span>
                    <span style="margin-left:10px;">{{info.plug_msh.evolution_num}}（次）<span style="color:rgb(171 0 0);">能量满后自动转化成次数</span></span>
                </div>
                <div>
                    <span class="btn" style="margin-top:22px;" v-if="info.evolution.e11.state == 0" @click="MshEvolution">进化</span>
                    <span class="btn" style="margin-top:22px;" v-if="info.evolution.e11.state == 1" @click="MshEvolutionReset">重置进化</span>
                </div>
            </div>
        </div>
      </div>
      <div class="pop">
      <div class="alert_bg pop-buy" v-if="BuyDisplay">
        <div class="alert_main">
          <i style="position: relative;left: 143px;top: 20px;z-index: 20;font-size: 18px;color: #bc9b9b;"  @click="BuyDisplay = false">x</i>
          <div class="alert_title"><span>提升</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <div>
                <div style="border: 1px dashed #444; position: relative">
                  <div style="
                      width: 78%;
                      display: inline-block;
                      float: left;
                      text-align: left;
                      padding: 10px 10px;
                      color: #ff7878;
                    ">
                    <p style="margin-bottom: 4px">
                      每提升1%概率需要支付：{{info.plug_msh.probability_buy_price}}<span v-if="info.plug_msh.probability_buy_type == 1" style="display: inline;padding: 0px;" >点卷</span><span v-if="info.plug_msh.probability_buy_type == 2" style="display: inline;padding:0px;">代币</span><span v-if="info.plug_msh.probability_buy_type == 3" style="display: inline;padding: 0px;">转生石</span>
                    </p>
                    <input type="text"
                           v-model="probability_num"
                           placeholder="输入需要概率数" />
                  </div>
                  <div style="
                      display: inline-block;
                      float: right;
                      position: absolute;
                      top: 32px;
                      right: 4px;
                    ">
                    <a class="btn cera"
                       href="javascript:;"
                       @click="BuyProbability">&nbsp;购买</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
    </div>
      <div class="alert">
        <!-- 弹出框 -->
        <div class="alert_bg"
             :style="MessageDisplay">
          <div class="alert_main">
            <div class="alert_title"><span>提示</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <span>{{ MessageMsg }}</span>
                <div class="btn"
                     @click="CloseAlert">关闭</div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
        <div class="alert_bg"
             :style="SuccessDisplay">
          <div class="alert_main">
            <div class="alert_title"><span>提示</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <span>{{ MessageMsg }}</span>
                <div class="btn"
                     @click="SuccessAlert">关闭</div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>

        
        <!-- 弹出框end -->
        <!-- 登陆框 -->
        <div class="alert_bg"
             :style="LoginDisplay">
          <div class="alert_main"
               style="margin-top: 166px; width: 350px">
            <div class="alert_title"><span>绑定|切换</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <p style="margin-bottom: 6px; display: flex">
                  <span style="padding: 0px 0px 0px 42px">游戏账号：</span><input type="text"
                         v-model="login_info.username"
                         class="service__input" />
                </p>
                <p style="margin-bottom: 6px; display: flex">
                  <span style="padding: 0px 0px 0px 42px">游戏密码：</span><input type="password"
                         v-model="login_info.password"
                         class="service__input" />
                </p>
                <p style="margin-bottom: 6px; display: flex">
                  <span style="padding: 0px 0px 0px 42px">绑定角色：</span>
                  <select v-model="login_info.role"
                          style="width: 174px">
                    <option v-for="(itmec, i) in role_data"
                            :key="i"
                            :value="itmec.charac_no">
                      {{ itmec.charac_name }}
                    </option>
                  </select>
                  <span style="background: #7478e4;padding: 3px 10px;margin-left: 2px;border-radius: 4%;" @click="getRole">获取</span>
                </p>
                <div>
                  <span style="padding: 0px 0px; color: #f90000"><span style="display: inline-block; padding: 0px 0px">{{
                                            LoginError
                                            }}</span></span>
                </div>
                <div style="text-align: center; padding: 10px 0px">
                  <div class="btn"
                       @click="login"
                       style="margin: 0px 0px; display: inline-block">
                    绑定
                  </div>
                  <div class="btn"
                       @click="LoginDisplay = 'display:none'"
                       style="margin: 0px 0px; display: inline-block">
                    取消
                  </div>
                </div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    
  <script>
  import $ from "jquery";
  import Vue from "vue";
  
  export default {
    name: "Msh",
    data () {
      return {
        MessageDisplay: "display:none", //弹出框显示
        SuccessDisplay: "display:none", //成功弹出框
        LoginDisplay: "display:none", //登陆弹出框
        PayDisplay: "display:none", //购买弹出框
        BuyDisplay: false, //升级弹出框
        LoginError: "",
        MessageMsg: "", //弹出框消息
        Loading: false,
        probability_num:"",
        info: {
          m_id:0,
          charac_no:0,
          charac_name: '', //当前绑定角色
          evolution:{
            e1:{skills:"",state:0},
            e2:{skills:"",state:0},
            e3:{skills:"",state:0},
            e4:{skills:"",state:0},
            e5:{skills:"",state:0},
            e6:{skills:"",state:0},
            e7:{skills:"",state:0},
            e8:{skills:"",state:0},
            e9:{skills:"",state:0},
            e10:{skills:"",state:0},
            e11:{skills:"",state:0},
          }, 
          task_lis:[],
          plug_msh:{
            energy_num:0,
            probability:0,
            dq_probability:0,
            level:0,
            dq_energy_num:0,
            evolution_num:0,
            percentage:0,
            probability_buy_type:0,
            probability_buy_price:0,
          },
          soaring:[],
          basis:{
            dq:{
              maxHP:0,
              maxMP:0,
              phy_attack:0,
              mag_attack:0,
              phy_defense:0,
              mag_defense:0,
              move_speed:0,
              attack_speed:0,
              cast_speed:0,
              element_resist_h:0,
              element_resist_b:0,
              element_resist_g:0,
              element_resist_a:0,
              lucky:0,
            },
            ls:{
              maxHP:0,
              maxMP:0,
              phy_attack:0,
              mag_attack:0,
              phy_defense:0,
              mag_defense:0,
              move_speed:0,
              attack_speed:0,
              cast_speed:0,
              element_resist_h:0,
              element_resist_b:0,
              element_resist_g:0,
              element_resist_a:0,
              lucky:0,
              skills:0,
            }
          },
        },
        login_info: {
          username: "",
          password: "",
          role: "",
        },
        role_data:[],
      };
    },
    created () {
      this.getInfo();
    },
    methods: {
      //登陆
      login () {
        if (this.Loading == true) {
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/PlugLogin",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
            role: this.login_info.role,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.LoginDisplay = "display:none";
              this.getInfo();
            } else {
              this.LoginError = response.data.msg;
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //获取角色
      getRole () {
        if (this.Loading == true) {
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/getRole",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.LoginError = "";
              this.role_data = response.data.info;
            } else {
              this.LoginError = response.data.msg;
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //获取基本配置信息
      getInfo () {
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/msh/getMshInfo",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.info = response.data.info;
            } else {
              this.PopMessage(true, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      MshTask(id){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/msh/MshTask",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            id:id,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.PopMessage(true, response.data.msg);
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //弹出框状态
      PopMessage (type, msg) {
        this.SuccessDisplay = "display:none";
        this.MessageDisplay = "display:none";
        this.MessageMsg = msg;
        if (type == false) {
          this.MessageDisplay = "display:block";
        } else {
          this.SuccessDisplay = "display:block";
        }
      },
      //关闭窗口
      CloseAlert () {
        this.SuccessDisplay = "display:none";
        this.MessageDisplay = "display:none";
      },
      SuccessAlert () {
        this.SuccessDisplay = "display:none";
        this.MessageDisplay = "display:none";
        this.getInfo();
      },
      BuyProbability(){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/msh/BuyProbability",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            num:this.probability_num,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            this.BuyDisplay = false;
            if (response.data.code == 200) {
              this.PopMessage(true, response.data.msg);
              
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      MshEvolution(){
        if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/msh/MshEvolution",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.PopMessage(true, response.data.msg);
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      MshEvolutionReset(){
         if(this.Loading == true){
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/msh/MshEvolutionReset",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.PopMessage(true, response.data.msg);
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      MenuActive (index) {
        this.index = index;
        $('.page').removeClass('active');
        $('.toptab').removeClass('active');
        $(this).addClass('active');
        $('.page').eq(index).addClass('active');
      },
    },
  };
  </script>
    <style scoped>
  @import "../../../public/static/plug/msh/static/css/game.css";
  </style>
  <style lang="scss" scoped>
  .main {
    /deep/ .el-row {
      margin-right: 3px !important;
      margin-left: 3px !important;
    }
  }
  
  .jobd{
    /deep/ .el-input__inner {
      height:20px !important;
      background-color:#101010 !important;
      border: 1px solid #444444 !important;
      border-radius:0 !important;
      font-size:12px !important;
      padding:0 5px  !important;
    }
    
  
   
  }
  
  .iyme-fy {
    /deep/ .el-pagination {
      color: #c5b48e;
    }
    /deep/ .el-pager li {
      background: none !important;
    }
    /deep/ .el-pagination button:disabled {
      background-color: #101010 !important;
    }
    /deep/ .el-pagination .btn-next {
      background: #101010 !important;
    }
    /deep/ .el-pagination .btn-prev,
    .el-pagination .btn-prev {
      background: #101010 !important;
    }
  }
  </style>
    
    <style>
  #root,
  body,
  html {
    min-width: 0px;
  }
  .el-cascader-node {
      height:20px !important;   
  }
  
  .el-cascader__dropdown{
    background: #101010  !important;
    border: 1px solid #616161  !important;
  }
  </style>
    